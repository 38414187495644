<template>
  <router-link
    to="/logs"
    class="VMenuProfileLink"
    :class="{
      resize: resize,
    }"
  >
    <div
      class="VMenuProfileLink__clickArea"
      @click="selectMenu"
    >
      <div class="VMenuProfileLink__help">
        {{ label }}
      </div>
      <VIconDetal />
      {{ label }}
    </div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="vProfileMenu__arrow-right"
      :class="{ active: showContent }"
      @click.stop.prevent="toggleList"
    >
      <path
        d="M8 10L12 14L16 10"
        stroke="#80869A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div
      v-if="showContent && !resize"
      class="VMenuProfileLink__list"
    >
      <vInput
        id="searchField"
        v-model="searchField"
        :value="searchField"
        placeholder="Поиск"
        @input="searchRestaurants"
      />
      <div
        v-for="item in GET_RESTAURANTS"
        :key="item.id"
        class="VMenuProfileLink__rest"
        :class="{ active: activeRestarurant === item.id }"
        @click="selectRestaurant(item.id)"
      >
        {{ item.name }} #{{ item.id }} ({{ item.code }})
        <i
          v-if="item.user?.email"
          class="VMenuProfileLink__email"
        >{{ item.user.email }}</i>
      </div>
    </div>
  </router-link>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import VIconDetal from '@/assets/images/icons/v-icon-detal.vue'
import vInput from '@/components/v-input'
export default {
  name: 'VLogsMenuLink',
  components: {
    VIconDetal,
    vInput
  },
  props: {
    label: {
      type: String,
      required: true,
      default: 'Link',
    },
    activeLink: {
      type: Number,
      required: false,
      default: 0,
    },
    linkName: {
      type: String,
      required: false,
      default: '',
    },
    currentRole: {
      type: String,
      required: true,
      default: 'manager',
    },
    roles: {
      type: Array,
      required: false,
      default: () => [],
    },
    resize: {
      type: Boolean,
      required: true,
      default: true,
    },
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['selectMenu', 'togglePreloader'],
  data() {
    return {
      showContent: false,
      showMoreLoading: false,
      activeRestarurant: 0,
      searchField: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_RESTAURANTS', 'GET_RESTAURANTS_PAGES']),
  },
  async mounted() {
    await this.FETCH_RESTAURANTS({
      token: this.token,
      search: '',
    })
  },
  methods: {
    ...mapActions('restaurant', [
      'FETCH_RESTAURANTS',
      'SHOW_MORE_RESTAURANTS',
      'SELECT_REST',
    ]),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    async searchRestaurants() {
      await this.throttleMethod()
    },
    throttleMethod: _.debounce(async function () {
      await this.FETCH_RESTAURANTS({
        token: this.token,
        search: this.searchField,
      })
    }, 500),
    selectMenu() {
      this.SELECT_REST(this.GET_RESTAURANTS[0])
      this.activeRestarurant = this.GET_RESTAURANTS[0].id
      this.$emit('selectMenu', { code: this.linkName, name: this.label })
    },
    toggleList() {
      this.showContent = !this.showContent
    },
    async selectRestaurant(id) {
      this.selectMenu()
      this.activeRestarurant = id
      const [selectRest] = this.GET_RESTAURANTS.filter((i) => i.id === id)
      this.SELECT_REST(selectRest)
      this.TOGGLE_PRELOADER(false)
    },
  },
}
</script>
<style lang="scss" scoped>
.VMenuProfileLink {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid #edeef1;
  font-size: 17px;
  color: #010d35;
  transition: color 0.2s;
  overflow: hidden;
  flex-wrap: wrap;
  min-height: 56px;
  height: auto;
  background: #fff;
  cursor: pointer;
  &:hover {
    overflow: initial;
  }
  @media (max-width: 769px) {
    font-size: 17px !important;
  }
  svg {
    &.active {
      stroke: #6764ff !important;
      transform: rotate(0deg);
      fill: none !important;
    }
    @media (max-width: 769px) {
      stroke: #80869a;
    }
  }
  &__email {
    display: flex;
    font-size: 12px;
    color: #b3abab;
    width: 100%;
  }
  &__rest {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    cursor: pointer;
    color: #010d35 !important;
    border-bottom: 1px solid #edeef1;
    padding-bottom: 8px;
    &:hover,
    &.active {
      color: #6764ff !important;
    }
  }
  &__clickArea {
    display: flex;
    height: 56px;
    align-items: center;
  }
  &__list {
    display: flex;
    width: 100%;
    height: calc(100vh - 280px);
    overflow: auto;
    flex-direction: column;
  }
  &__help {
    position: absolute;
    top: 50%;
    left: calc(100% + 25px);
    transform: translateY(-50%);
    background: #fff;
    color: #80869a;
    border: 1px solid #e6e7eb;
    z-index: -1;
    padding: 4px;
    border-radius: 0 6px 6px 0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    border-left: none;
    font-size: 12px;
    width: max-content;
    transition: all 0.2s;
    opacity: 0;
    @media (max-width: 769px) {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: -8px;
      top: 50%;
      border-top: 1px solid #e6e7eb;
      border-left: 1px solid #e6e7eb;
      width: 16px;
      height: 16px;
      background: white;
      transform: translateY(-50%) rotate(-45deg);
      z-index: -1;
    }
  }
  &.active {
    svg {
      stroke: #6764ff !important;
      @media (max-width: 769px) {
        stroke: #80869a;
      }
    }
  }
  &:hover {
    color: #6764ff;
    svg {
      stroke: #6764ff;
    }
  }
  &.resize {
    font-size: 0;
    &:hover {
      .VMenuProfileLink__help {
        opacity: 1;
        z-index: 1;
      }
    }
    button.vButton {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }
}
</style>
